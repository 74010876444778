<template>
  <div v-if="user">
    <!-- SEARCH -->
    <Search 
      v-if="user && user.organization"
      @selected="startSearch" 
      :orders="confOrders" 
      ref="search" 
    />

    <preloader v-if="status.gettingNewOrders && !confOrders.length" />

    <v-alert 
      v-if="!confOrders.length && !status.gettingNewOrders" 
      border="left"
      type="info"
      text
    >
      No orders found.
    </v-alert>
    
    <template v-if="confOrders.length">
      <!-- HEADERS -->
      <v-row class="d-none d-md-flex mb-2 grey--text font-weight-bold caption text-uppercase" no-gutters>
        <v-col cols="1" :style="{ maxWidth: '80px' }"></v-col>
        <v-col class="text-left px-5" cols="3">Client</v-col>
        <v-col cols="2 px-5">Order Type</v-col>
        <v-col cols="2 px-5">Estimated Delivery</v-col>
        <v-col cols="2 px-5">Project Manager</v-col>
        <v-col cols="1 px-5">Status</v-col>
      </v-row>

      <v-hover 
        v-for="order in confOrders"
        v-slot="{ hover }"
        :key="order.id" 
      >
        <v-card
          :to="{ name: 'OrderDetails', params: { id: order.id } }"
          class="d-flex rounded-lg mb-2 cursor-default"
          :class="{ 'box-shadow' : hover }"
          :style="{ transition: 'all 0.3s'}"
          outlined
        >
          <v-row no-gutters align="center">
            <v-col cols="1" 
              class="fill-height d-flex align-center justify-center order_priority" 
              style="border-right: 1px solid #e9ebf4; max-width: 80px !important;"
              :class="
                user.role === 'client' ? `${order.status == 'completed' ? 'order_priority__medium' : 'order_priority__none'}` :  `order_priority__${order.priority || 'none'}`
              "
            >
              <v-card-text class="text-center px-0 py-0">
                <v-avatar
                  size="24"
                  tile
                >
                  <img v-if="['collecting_content', 'in_progress', 'checking_quality', 'reviewing_with_client', 'pending_payment'].includes(order.status)" :src="require('@/assets/badge-icon-open.svg')" alt="open">
                  <v-icon v-else-if="order.status == 'escalated'" color="error">mdi-alert-circle-outline</v-icon>
                  <img v-else :src="require('@/assets/badge-icon-completed.svg')" alt="delivered">
                </v-avatar>
              </v-card-text>
            </v-col>
            <!-- CLIENT -->
            <v-col cols="3">
              <v-card-text class="d-flex align-center">
                <v-badge 
                  :value="haveMessage(order)"
                  color="transparent" 
                  size="15"
                  overlap
                  bottom 
                  left 
                >
                  <template #badge>
                    <span class="wave" :style="{ fontSize: '20px' }">👋</span>
                  </template>
                  
                  <user-photo
                    class="mr-3 align-self-start"
                    :id="order.user"
                    photoSize="thumb"
                    :size="30"
                    rounded
                  />
                </v-badge>

                <div>
                  <div class="caption">
                    <span class="blue-grey--text">{{ $store.getters['users/getFullName'](order.user) }}</span>
                    <span class="grey--text ml-1">({{ order.created | fromNowComplete }})</span>
                  </div>
                </div>
              </v-card-text>
            </v-col>
            <!-- ORDER TYPE -->
            <v-col cols="2">
              <v-card-text>
                <div class="caption blue-grey--text">{{ $store.getters['order_types/type']($store.getters['orderform/form'](order.form).orderType).name }}</div>
                <div class="caption info--text" :style="{ lineHeight: '1 !important' }">#{{ order.orderNumber }}</div>
              </v-card-text>
            </v-col>
            <!-- ESTIMATED DELIVERY DATE -->
            <v-col cols="2">
              <v-card-text class="caption">
                <span class="blue-grey--text">
                  {{ order.dueDate ? `${$options.filters.formatDate(order.dueDate)}` : 'TBA' }}
                </span>
                <span v-if="order.dueDate && moment(order.dueDate.toDate()).diff(moment(), 'hours') > -24 && moment(order.dueDate.toDate()).diff(moment(), 'hours') < 0" class="info--text">
                  today
                </span>
                <span v-else-if="order.dueDate" class="ml-1" :class="$overdue(order) ? 'error--text' : 'grey--text lighten-2' ">
                  {{ moment(order.dueDate.toDate()).fromNow() }}
                </span>
              </v-card-text>
            </v-col>
            <!-- PROJECT MANAGER -->
            <v-col cols="2">
              <v-card-text class="d-flex">
                <Assignee :order="order" small />
              </v-card-text>
            </v-col>
            <!-- STATUS -->
            <v-col cols="1">
              <v-card-text class="d-flex align-center">
                <status-badge :order="order" />
              </v-card-text>
            </v-col>
            <v-col class="text-right pr-3">
              <v-menu offset-y left>
                <template #activator="{ on }">
                  <v-btn @click.prevent v-on="on" icon>
                    <v-icon color="grey" small>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item :to="{ name: 'OrderDetails', params: { id: order.id } }">
                    <v-list-item-title>View</v-list-item-title>
                  </v-list-item>
                  <template v-if="$store.getters['orders/canManage']">
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-title class="grey--text caption">Update Priority</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateOrderPriority(order, 'high')">
                      <v-list-item-title>High</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateOrderPriority(order, 'medium')">
                      <v-list-item-title>Medium</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateOrderPriority(order, 'low')">
                      <v-list-item-title>Low</v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-divider></v-divider>
                  <v-list-item @click="confirmDelete(order)">
                    <v-list-item-title class="error--text">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>
    </template>

    <div class="text-center">
      <v-btn 
        @click="getNewOrders($route.name == 'Completed')"
        :loading="status.gettingNewOrders"
        color="primary" 
        class="mt-5" 
        small
      >Load More</v-btn>
    </div>

    <!-- CONFIRM DELETE -->
    <confirm-delete 
      @confirmed="deleteConfirmed()"
      @cancel="closeDialog()"
      textConfirmation="Order"
      :message="deleteMessage"
      :show="deleteDialog"
      :deleting="status.deleting"
      captcha
    />

    <!-- EMPLOYEE DIALOG -->
    <employee-dialog
      ref="employeeDialog"
      @confirmed="updateAssigneeConfirmed"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { startCase, orderBy } from 'lodash'
import { mapState, mapActions } from 'vuex'

import Search from './components/Search.vue'
import Assignee from './components/Assignee'
import StatusBadge from './components/StatusBadge.vue'
import EmployeeDialog from '../users/components/EmployeeDialog.vue'

export default {
  metaInfo: {
    title: 'Orders'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      moment,
      startCase,
      search: {},
      order: null,
      toDelete: null,
      deleting: false,
      resentMessages: [],
      deleteDialog: false,
      deleteMessage: 'null',
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *-----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.orders.status,
      orders: state => state.orders.newOrders,
      completedOrders: state => state.orders.completedOrders,
      orderResponseTime: state => state.generalSettings.settings.orderResponseTime,
    }),

    confOrders: function () {
      let routeName = this.$route.name
      let orders = ['InProgressOrders', 'Orders'].includes(routeName) ? this.orders : this.completedOrders

      orders = orders.filter(order => {
        return ['InProgressOrders', 'Orders'].includes(routeName) ? order.status !==  'completed' : order.status == 'completed'
      })

      if (Object.keys(this.status.search).length) {
        orders = orders.filter(order => order.status !== 'pending_payment')
      }

      return orderBy(orders, 'orderNumber', 'desc')
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'user': {
      handler(user) {
        if (user) this.getOrders()
      },
      immediate: true
    },
    
    '$route': {
      handler(user) {
        if (user) this.getOrders()
      },
    },

    'orders': {
      handler(orders) {
        if (orders && orders.length) {
          orders.forEach(order => {
            this.getOrderMessage(order)
          })
        }
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Search,
    Assignee,
    StatusBadge,
    EmployeeDialog,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('orders', [
      'updateOrderField',
      'getNewOrders',
      'deleteOrder'
    ]),

    getOrders() {
      if (!this.status.firstLoad && ['InProgressOrders', 'Orders'].includes(this.$route.name) )
        this.getNewOrders()
      else if (!this.status.completedFirstLoad && this.$route.name == 'CompletedOrders')
        this.getNewOrders(true)
    },

    confirmDelete(order) {
      this.toDelete = order
      this.deleteMessage = `Delete order #<strong>${order.orderNumber}</strong>?`
      this.deleteDialog = true
    },

    closeDialog() {
      this.deleteDialog = false
    },

    deleteConfirmed() {
      Promise.resolve(this.deleteOrder(this.toDelete))
      .then(() => {
        this.closeDialog()
      })
    },

    startSearch(value) {
      if (value) {
        this.$store.commit('orders/resetOrders')
        this.$store.commit('orders/updateSearch', { value })
        this.getNewOrders(this.$route.name == 'CompletedOrders')
      }
    },

    attemptUpdateAssignee(order) {
      this.$refs.employeeDialog.showDialog()
      this.order = order
    },

    updateAssigneeConfirmed(value) {
      this.$refs.employeeDialog.loading = true
      
      Promise.resolve(
        this.updateOrderField({
          value,
          field: 'assignee',
          order: this.order,
          message: 'Project manager updated',
        })
      )
      .then(() => {
        this.$refs.employeeDialog.closeDialog()
        this.$store.dispatch('orders/sendAssigneeNotification', {
            order: this.order.id,
            user: value
          })
      })
    },

    getOrderMessage(order) {
      if (!this.haveMessage(order) && order.status !== 'completed') {
        order.ref
        .collection('messages')
        .orderBy('created', 'desc')
        .limit(1).get()
        .then((snapshot) => {
          if (snapshot.size) {
            let doc = snapshot.docs[0]
            let created = moment().diff(doc.data().created.toDate(), 'hours')
            
            if (
              order.user == doc.data().sender && 
              doc.data().type !== 'status_change' &&
              created >= this.orderResponseTime &&
              !doc.data().note
            ) {
              this.resentMessages.push(doc)
            }
          }
        })
        .catch(error => {
          console.log(error.message)
        })
      }
    },

    haveMessage(order) {
      return !!this.resentMessages.find(m => {
        return m.ref.parent.parent.id == order.id && order.user !== this.user.userid
      })
    },

    updateOrderPriority(order, priority) {
      this.updateOrderField({
        order,
        field: 'priority',
        value: priority
      })
    }
  },
}
</script>

<style lang="scss">
.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}

.order_priority {
  max-width: 80px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
  border-right: 1px solid #e9ebf4; 
  box-shadow: -4px 0 0 var(--v-dark-lighten5);

  &__high {
    box-shadow: -4px 0 0 var(--v-error-darken1);
  }
  
  &__medium {
    box-shadow: -4px 0 0 var(--v-success-darken1);
  }
  
  &__low {
    box-shadow: -4px 0 0 var(--v-dark-lighten5);
  }
}
</style>