<template>
  <div class="d-flex justify-space-between align-center mb-5">
    <div class="caption grey--text">
      Showing <span class="font-weight-bold">{{ orders.length }}</span> orders
    </div>

    <div class="d-flex flex-grow-1 justify-end gap-12 align-center flex-wrap">
      <v-btn 
        @click="clearSearch()" 
        v-if="search.search" 
        color="default" 
        small
      >
        <v-icon small left>mdi-close</v-icon>
        Clear Filters
      </v-btn>

      <!-- EMPLOYEE VIEW -->
      <v-sheet 
        v-if="
          $store.state.user.user.organization 
          && !$store.getters['orders/canManage']
          && !Object.keys(status.search).length
        "
        max-width="230"
        width="100%" 
      >
        <v-select
          class="dashboard-select field-shadow"
          :items="['assignee', 'follower']"
          v-model="status.employeeView"
          prefix="Show orders as"
          @change="changeView()"
          item-value="value"
          hide-details
          outlined
          dense
        ></v-select>
      </v-sheet>

      <!-- SEARCH TYPE -->
      <v-sheet width="100%" max-width="250">
        <v-select
          class="dashboard-select field-shadow"
          v-model="search.search"
          @change="resetValues"
          prefix="Search by: "
          item-value="value"
          :items="searchBy"
          item-text="text"
          hide-details
          outlined
          dense
        ></v-select>
      </v-sheet>
      
      <!-- SELECT ASSIGNEE -->
      <v-sheet v-if="['assignee', 'follower'].includes(search.search)" width="100%" max-width="350">
        <v-autocomplete
          :items="orderBy(employees, 'fullName', 'asc')"
          class="dashboard-select field-shadow"
          :loading="gettingEmployees"
          placeholder="Select user"
          item-text="fullName"
          item-value="userid"
          v-model="search.assignee"
          @input="updated"
          hide-details
          outlined
          dense
        >
          <template #selection="data">
            <v-chip
              @click:close="search.assignee = null"
              :input-value="data.selected"
              @click="data.select"
              v-bind="data.attrs"
              class="pl-1"
              close
              small
            >
              <user-photo
                :id="data.item.userid"
                photoSize="thumb"
                :size="22"
                class="mr-2"
              />

              {{ data.item.fullName }}
            </v-chip>
          </template>
          <template #item="data">
            <v-list-item-avatar :style="{ overflow: 'visible' }" class="mr-1" rounded>
              <user-photo
                :id="data.item.userid"
                photoSize="thumb"
                :size="30"
                rounded
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.fullName }}
              </v-list-item-title>
              <v-list-item-subtitle class="caption">
                {{ data.item.employeeRole }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-sheet>
      
      <!-- SEARCH BY ORDER NUMBER -->
      <v-sheet v-if="search.search == 'orderNumber'" max-width="250">
        <v-text-field
          @keydown.enter="updated(parseInt($event.target.value))"
          placeholder="Type order number"
          v-model.number="orderNumber"
          class="field-shadow"
          hide-details
          outlined
          dense
        />
      </v-sheet>
      
      <!-- SELECT ORDER TYPES -->
      <v-sheet v-if="search.search == 'orderType'" width="100%" max-width="350">
        <v-autocomplete
          :items="orderBy(orderTypes, 'name', 'asc')"
          class="dashboard-select field-shadow"
          placeholder="Select order type"
          :loading="gettingForms"
          v-model="search.orderType"
          item-text="name"
          @input="updated"
          item-value="id"
          hide-details
          outlined
          dense
        ></v-autocomplete>
      </v-sheet>
      
      <!-- CLIENT -->
      <template v-if="search.search == 'user'">
        <v-btn 
          @click="showUsersDialog()"
          class="primary--text" 
          v-if="!client" 
          depressed 
          small
        >Search client</v-btn>

        <v-chip @click="showUsersDialog()" v-if="client" class="caption">
          <user-photo
            photoSize="thumb"
            :id="client"
            class="mr-2"
            :size="22"
          />

          {{ $store.getters['users/user'](client).fullName }} ({{ $store.getters['users/user'](client).email }})
        </v-chip>
      </template>
    </div>

    <!-- EMPLOYEE DIALOG -->
    <find-user-dialog ref="userDialog" @selected="clientSelected" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import db from '@/firebase/init'
import { startCase, orderBy } from 'lodash'
import FindUserDialog from '../../users/components/FindUserDialog'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *-----------------------------------------------------------------------------*/  
  props: {
    orders: Array
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      orderBy,
      startCase,
      client: null,
      assignee: null,
      orderType: null,
      orderNumber: null,
      gettingForms: false,
      gettingEmployees: false,
      searchBy: [
        { text: 'Assignee', value: 'assignee' },
        { text: 'Order Number', value: 'orderNumber' },
        { text: 'Client', value: 'user' },
        { text: 'Follower', value: 'follower' },
        { text: 'Order Type', value: 'orderType' },
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.orders.status,
      search: state => state.orders.search,
      employees: state => state.users.employees,
      orderTypes: state => state.order_types.types,
    })
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    FindUserDialog
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    updated(value) {
      if (this.search.search == 'orderType') {
        this.gettingForms = true

        db.collection('order_forms')
        .where('orderType', '==', value)
        .get()
        .then((snapshot) => {
          this.gettingForms = false

          if (snapshot.size) {
            let forms = snapshot.docs.map(doc => doc.id)
            this.$emit('selected', forms)
          }
          else {
            this.$store.dispatch('showError', 'There are no forms associated with this order type.')
          }
        })
      }
      else if (value) {
        this.$emit('selected', value)
      }
    },

    resetValues(value) {
      this.client = null
      this.assignee = null
      this.orderType = null
      this.orderNumber = null

      if (value == 'clear') this.$emit('selected', {})
    },

    showUsersDialog() {
      this.$refs.userDialog.dialog = true
    },

    clientSelected(value) {
      this.client = value
      this.updated(value)
      this.$store.dispatch('users/getUser', value)
    },

    clearSearch() {
      this.$store.commit('orders/resetState')
      this.$store.dispatch('orders/getNewOrders', this.$route.name == 'CompletedOrders')
    }

    // changeView() {
    //   this.$store.commit('orders/clearOrders')
    //   this.$store.commit('orders/updateStatus', { firstLoad: false })
    //   this.$store.commit('orders/updateStatus', { completedFirstLoad: false })
    //   this.$store.commit('orders/updateStatus', { lastVisible: null })
    //   this.$store.commit('orders/updateStatus', { lastCompletedVisible: null })
    //   this.$store.dispatch('orders/getNewOrders', this.$route.name == 'CompletedOrders')
    //   this.resetValues('clear')
    //   this.search.search = 'clear'
    // }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.$store.state.users.status.firstLoadEmployees) 
      this.$store.dispatch('users/getEmployees')
    
    if (!this.$store.state.order_types.status.firstLoad) 
      this.$store.dispatch('order_types/getTypes')
  }
}
</script>